import {Component, OnInit} from '@angular/core'
import { MatTableDataSource, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatNoDataRow } from '@angular/material/table'
import { MatButton } from '@angular/material/button'

export interface CorpPrices {
  feeType: string
  lowVol: string
  highVol: string
  majVol: string
  flabVol: string
}

@Component({
  selector: 'aku-idid',
  templateUrl: './idid.component.html',
  styleUrls: ['./idid.component.scss'],
  imports: [MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatNoDataRow, MatButton]
})
export class IdidComponent implements OnInit {
  public dataSource: MatTableDataSource<CorpPrices> = new MatTableDataSource<CorpPrices>()
  public columnsToDisplay: string[] = ['feeType', 'lowVol', 'highVol', 'majVol', 'flabVol']
  public thePrices: CorpPrices[] = [{
    feeType: 'Monthly fee',
    lowVol: '399 kr',
    highVol: '999 kr',
    majVol: '1999 kr',
    flabVol: '14950 kr'
  },
  {
    feeType: 'User accounts',
    lowVol: '3',
    highVol: '15',
    majVol: 'unlimited',
    flabVol: 'unlimited'
  },
  {
    feeType: 'Transactional fee one login',
    lowVol: '0,99 kr',
    highVol: '0,49 kr',
    majVol: '0,45 kr',
    flabVol: '0,39 kr'
  },
  {
    feeType: 'Transactional fee one scanning',
    lowVol: '0,49 kr',
    highVol: '0,39 kr',
    majVol: '0,29 kr',
    flabVol: '0,23 kr'
  },
  {
    feeType: 'Record retention cost (per 1-5Mrecords  per month)',
    lowVol: '25 kr',
    highVol: '20 kr',
    majVol: '18 kr',
    flabVol: '17 kr'
  },
  {
    feeType: 'Record retention cost (per 5Mrecords  per month)',
    lowVol: '99 kr',
    highVol: '79 kr',
    majVol: '59 kr',
    flabVol: '49 kr'
  },
  {
    feeType: 'Automatic GDPR compliant retention (per month)',
    lowVol: '89 kr',
    highVol: '49 kr',
    majVol: 'included',
    flabVol: 'included'
  },
  {
    feeType: 'Customer database (ie, other parameters than in bank-id)',
    lowVol: '99 kr',
    highVol: '79 kr',
    majVol: '59 kr',
    flabVol: 'included'
  },
  {
    feeType: 'Integration with business system',
    lowVol: '1500 kr/hr',
    highVol: '1500 kr/hr',
    majVol: '1500 kr/hr',
    flabVol: 'Give us a call!'
  },
  ]

  public ngOnInit(): void {
    this.dataSource = new MatTableDataSource<CorpPrices>(this.thePrices)
  }
}
