import {Component} from '@angular/core'
import { RouterLink } from '@angular/router'
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu'

@Component({
  selector: 'aku-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  imports: [RouterLink, MatMenuTrigger, MatMenu, MatMenuItem]
})
export class HeaderComponent {}
