import {Component} from '@angular/core'
import {RouterOutlet} from '@angular/router'
import { HeaderComponent } from './header/header.component'

@Component({
  selector: 'aku-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [HeaderComponent, RouterOutlet]
})

export class AppComponent  {
}



