import {Component} from '@angular/core'
import {NextArrowComponent} from '../../common/next-arrow/next-arrow.component'
import {HowWeWorkComponent} from '../1-how-we-work/how-we-work.component'
import {AboutUsComponent} from '../2-about-us/about-us.component'
import {ClientsComponent} from '../3-clients/clients.component'
import {WeAreAkiruComponent} from '../4-we-are-akiru/we-are-akiru.component'

@Component({
  selector: 'aku-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss'],
  imports: [NextArrowComponent, HowWeWorkComponent, AboutUsComponent, ClientsComponent, WeAreAkiruComponent]
})

export class StartComponent {
}
