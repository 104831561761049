import { Routes } from '@angular/router'
import {StartComponent} from './0-pages/0-start/start.component'
import {IdidComponent} from './products/idid/idid.component'

export const routes: Routes = [
  {
    path: 'id',
    component: IdidComponent,
  },
  {
    path: '**',
    component: StartComponent,
  }
]
