import {Component} from '@angular/core'

@Component({
  selector: 'aku-we-are-akiru',
  templateUrl: './we-are-akiru.component.html',
  styleUrls: ['./we-are-akiru.component.scss']
})
export class WeAreAkiruComponent {
  currentYear = new Date().getFullYear()
}
