<div class="holder">
  <div class="header home">
    <div [matMenuTriggerFor]="menu" aria-controls="menu" aria-haspopup="true"
         aria-label="Huvudmeny" class="menu">
            <span class="material-symbols-outlined">
              menu
            </span>
    </div>
    <mat-menu #menu="matMenu">
      <button
        [routerLink]="['']"
        mat-menu-item
        role="menuitem">Hem
      </button>
      <button
        [routerLink]="['id']"
        mat-menu-item
        role="menuitem">Digitalt ID
      </button>
    </mat-menu>
  </div>
</div>
