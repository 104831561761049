import {Component} from '@angular/core'
import { NgOptimizedImage } from '@angular/common'

@Component({
  selector: 'aku-how-we-work',
  templateUrl: './how-we-work.component.html',
  styleUrls: ['./how-we-work.component.scss'],
  imports: [NgOptimizedImage]
})
export class HowWeWorkComponent {

}
