import {Component, Input} from '@angular/core'
import { NgClass } from '@angular/common'

@Component({
  selector: 'aku-next-arrow',
  templateUrl: './next-arrow.component.html',
  styleUrls: ['./next-arrow.component.scss'],
  imports: [NgClass]
})
export class NextArrowComponent {

  @Input() text = 'TExt'

  @Input() white = false
}
